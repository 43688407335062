import { track, useEditor } from "@tldraw/tldraw";
import {useEffect, useRef } from "react";
import {uploadImagesToCanvas, uploadPdfToCanvas} from "./Utils/FileUploads";
import ZoomMenu from "./Components/ZoomMenu";
import PagesMenu from './Components/Pages/PagesMenu';
import SelectTool from "./Components/Toolbar/Icons/SelectTool";
import PanTool from "./Components/Toolbar/Icons/PanTool";
import DrawTool from "./Components/Toolbar/Icons/DrawTool";
import EraserTool from "./Components/Toolbar/Icons/EraserTool";
import NoteTool from "./Components/Toolbar/Icons/NoteTool";
import ArrowTool from "./Components/Toolbar/Icons/ArrowTool";
import ShapeTool from "./Components/Toolbar/Icons/ShapeTool";
import UploadTool from "./Components/Toolbar/Icons/UploadTool";
import ImageTool from "./Components/Toolbar/Icons/ImageTool";
import {CurrentUsers} from "./Components/CurrentUsers";
import {handleDarkModeToggle} from "./Utils/Utils";
import PaperTitle from "./Components/PaperTitle";
import TextTool from "./Components/Toolbar/Icons/TextTool";

let PixelPaperUI;

export default PixelPaperUI = track(() => {

    const editor = useEditor();

    window.editor = editor;

    useEffect(() => {
        editor.updateInstanceState({isDebugMode: false})
    }, []);

    handleDarkModeToggle();

    const handleToolSelect = (toolId) => {

        editor.setCurrentTool(toolId);

    };

    const fileInputRef = useRef(null);
    const imageInputRef = useRef(null);

    /**
     * We can upload:
     * 1. Any Image. This just uploads it to the current page.
     * 2. Any PDF document
     *      This will show a modal that asks them if they'd like to upload all pages to a single PixelPaper page (and lock the zoom / scroll)
     *      or each page to a new PixelPaper page.
     */
    const uploadDocument = () => {
        fileInputRef.current.click();
    }

    const handleFileChange = async (event) => {

        const file = event.target.files[0];

        if (file) {

            if(file.type.startsWith('image/')) {
                await uploadImagesToCanvas(editor, event.target.files);
            }
            else if(file.type === 'application/pdf') {
                uploadPdfToCanvas(editor, file);
            }

        }
    };

    return (
        <div className="custom-layout flex-grow-0 flex-shrink-0">

            <div className="flex flex-col h-screen">

                <div className="flex justify-between px-4 border-b
                                border-gray-200 dark:border-gray-500
                                bg-white dark:bg-gray-800">

                    <div className={`w-1/3 flex space-x-2`}>
                        <PagesMenu/>
                    </div>


                    <div className={`w-1/3 flex justify-center`}>
                        <PaperTitle />
                    </div>

                    <div className={`flex flex-row space-x-1 w-1/3 hidden sm:flex justify-end`}>

                        <CurrentUsers/>

                        <ZoomMenu
                            editor={editor}
                        />
                    </div>

                </div>

                <div className="flex-grow"></div>

                <div className="flex items-center justify-center">

                    <div className="max-w-2xl w-fit all-events bg-white dark:bg-gray-800
                    border border-gray-200 dark:border-gray-700 rounded flex">

                        <SelectTool
                            tool={editor.getCurrentTool().id}
                            onSelect={handleToolSelect}
                        />

                        {
                            editor.getInstanceState().followingUserId === null &&
                            <PanTool
                                tool={editor.getCurrentTool().id}
                                onSelect={handleToolSelect}
                            />
                        }

                        <DrawTool
                            tool={editor.getCurrentTool().id}
                            onSelect={handleToolSelect}
                        />

                        <EraserTool
                            tool={editor.getCurrentTool().id}
                            onSelect={handleToolSelect}
                        />

                        <TextTool
                            tool={editor.getCurrentTool().id}
                            onSelect={handleToolSelect}
                        ></TextTool>

                        <NoteTool
                            tool={editor.getCurrentTool().id}
                            onSelect={handleToolSelect}
                        />

                        <ArrowTool
                            tool={editor.getCurrentTool().id}
                            onSelect={handleToolSelect}
                        />

                        <ShapeTool
                            tool={editor.getCurrentTool().id}
                            onSelect={handleToolSelect}
                        />

                        <UploadTool onSelect={uploadDocument}/>

                    </div>
                </div>
            </div>


            <input
                type="file"
                multiple
                ref={fileInputRef}
                className={`hidden`}
                accept="application/pdf,image/png, image/jpeg, image/webp"
                onChange={handleFileChange}
            />

            <div className={`absolute bottom-2 right-12`}>
                <svg className={`h-8 w-auto`}
                     width="3001" height="1000" viewBox="0 0 3001 1000" fill="currentColor" stroke="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M590.656 300.449C590.656 350.155 550.362 390.449 500.656 390.449C450.951 390.449 410.656 350.155 410.656 300.449C410.656 250.744 450.951 210.449 500.656 210.449C550.362 210.449 590.656 250.744 590.656 300.449Z"
                        fill="#4b5563"/>
                    <path
                        d="M569.431 719.011C554.184 751.832 513.425 810.6 471.093 810.449C439.089 810.334 432.451 779.545 453.679 759.593C471.06 743.256 481.925 711.518 485.674 686.874C486.089 684.146 484.118 681.677 481.402 681.195C441.736 674.155 410.656 640.318 410.656 597.778C410.656 549.548 449.639 510.449 497.726 510.449C537.662 510.449 567.898 532.686 581.095 562.846C599.934 605.901 588.212 678.579 569.431 719.011Z"
                        fill="#4b5563"/>
                    <path
                        d="M2613.29 385.681V239.319C2613.29 227.956 2622.51 218.75 2633.88 218.75H2642.14C2653.51 218.75 2662.73 227.956 2662.73 239.319V276.23C2662.73 284.859 2669.73 291.855 2678.36 291.855H2713.61C2722.24 291.855 2729.24 284.859 2729.24 276.23V239.319C2729.24 227.956 2738.46 218.75 2749.83 218.75H2758C2769.37 218.75 2778.59 227.956 2778.59 239.319V385.681C2778.59 397.044 2769.37 406.25 2758 406.25H2749.83C2738.46 406.25 2729.24 397.044 2729.24 385.681V348.682C2729.24 340.052 2722.24 333.057 2713.61 333.057H2678.36C2669.73 333.057 2662.73 340.052 2662.73 348.682V385.681C2662.73 397.044 2653.51 406.25 2642.14 406.25H2633.88C2622.51 406.25 2613.29 397.044 2613.29 385.681Z"
                        fill="#4b5563"/>
                    <path
                        d="M2391.97 239.319V385.681C2391.97 397.029 2382.81 406.25 2371.48 406.25H2363.28C2351.95 406.25 2342.79 397.029 2342.79 385.681V239.319C2342.79 227.971 2351.95 218.75 2363.28 218.75H2371.48C2382.81 218.75 2391.97 227.971 2391.97 239.319Z"
                        fill="#4b5563"/>
                    <path
                        d="M2098.23 391.43L2055.54 245.069C2051.69 231.898 2061.6 218.75 2075.33 218.75H2085.93C2095.52 218.75 2103.86 225.361 2106.01 234.702L2123.02 307.747C2124.5 314.095 2133.49 314.225 2135.16 307.923L2154.63 234.085C2157.01 225.045 2165.2 218.75 2174.56 218.75H2186.66C2196.03 218.75 2204.22 225.05 2206.6 234.096L2226.09 308.163C2227.75 314.468 2236.74 314.341 2238.22 307.992L2255.31 234.698C2257.46 225.359 2265.8 218.75 2275.39 218.75H2285.92C2299.64 218.75 2309.55 231.891 2305.71 245.06L2263.08 391.421C2260.52 400.21 2252.45 406.25 2243.29 406.25H2227.61C2218.49 406.25 2210.45 400.27 2207.85 391.541L2186.68 320.482C2184.91 314.534 2176.49 314.525 2174.71 320.47L2153.38 391.541C2150.78 400.27 2142.74 406.25 2133.62 406.25H2118.03C2108.86 406.25 2100.8 400.215 2098.23 391.43Z"
                        fill="#4b5563"/>
                    <path
                        d="M2443.23 218.75H2561.82C2573.2 218.75 2582.44 227.945 2582.44 239.307C2582.44 250.668 2573.2 259.863 2561.82 259.863H2537.03C2531.5 259.863 2527.03 264.34 2527.03 269.863V385.681C2527.03 397.049 2517.78 406.25 2506.4 406.25H2498.75C2487.36 406.25 2478.12 397.049 2478.12 385.681V269.863C2478.12 264.34 2473.64 259.863 2468.12 259.863H2443.23C2431.86 259.863 2422.62 250.668 2422.62 239.307C2422.62 227.945 2431.86 218.75 2443.23 218.75Z"
                        fill="#4b5563"/>
                    <path
                        d="M1174.15 218.75H1198.79C1207.14 218.75 1214.67 223.792 1217.83 231.514L1252.44 315.456C1254.57 320.617 1261.88 320.611 1264 315.446L1298.43 231.514C1301.6 223.792 1309.12 218.75 1317.47 218.75H1342.11C1353.48 218.75 1362.69 227.958 1362.69 239.319V385.681C1362.69 397.042 1353.48 406.25 1342.11 406.25H1335.02C1323.66 406.25 1314.44 397.042 1314.44 385.681L1314.32 335.036C1314.31 328.148 1304.79 326.348 1302.26 332.753L1278.8 392.085C1275.7 399.936 1268.11 405.094 1259.66 405.094H1256.63C1248.17 405.094 1240.57 399.919 1237.48 392.048L1214.01 332.368C1211.49 325.952 1201.96 327.745 1201.95 334.639L1201.82 385.681C1201.82 397.042 1192.61 406.25 1181.25 406.25H1174.15C1162.79 406.25 1153.58 397.042 1153.58 385.681V239.319C1153.58 227.958 1162.79 218.75 1174.15 218.75Z"
                        fill="#4b5563"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M1449.94 391.836L1456.06 372.444C1456.88 369.846 1459.29 368.079 1462.02 368.075L1512.24 368.014C1514.96 368.01 1517.38 369.769 1518.2 372.362L1524.43 391.848C1527.14 400.429 1535.14 406.25 1544.17 406.25H1553.51C1567.64 406.25 1577.66 392.459 1573.12 379.099L1523.38 232.738C1520.53 224.368 1512.64 218.75 1503.77 218.75H1470.61C1461.74 218.75 1453.84 224.368 1451 232.738L1401.26 379.099C1396.72 392.459 1406.74 406.25 1420.87 406.25H1430.19C1439.23 406.25 1447.23 400.423 1449.94 391.836ZM1481.04 292.978C1482.89 287.171 1491.12 287.182 1492.95 292.994L1501.78 320.91C1503.06 324.938 1500.05 329.044 1495.82 329.044L1478.08 329.044C1473.85 329.044 1470.84 324.925 1472.13 320.893L1481.04 292.978Z"
                          fill="#4b5563"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M1681.81 406.25C1700.72 406.25 1717.2 402.564 1731.17 395.082C1745.14 387.538 1755.9 376.688 1763.41 362.593C1770.97 348.488 1774.7 331.727 1774.7 312.411C1774.7 293.155 1770.97 276.454 1763.41 262.407C1755.84 248.313 1745.06 237.495 1731.09 230.01C1717.18 222.465 1700.69 218.75 1681.72 218.75H1632.22C1620.84 218.75 1611.59 227.951 1611.59 239.319V385.681C1611.59 397.049 1620.84 406.25 1632.22 406.25H1681.81ZM1704.94 358.549C1698.32 361.764 1690.09 363.435 1680.15 363.435C1669.66 363.435 1661.15 354.928 1661.15 344.435V280.095C1661.15 269.946 1669.38 261.719 1679.53 261.719C1689.71 261.719 1698.09 263.422 1704.76 266.693C1711.35 269.842 1716.39 275.008 1719.84 282.326C1723.29 289.595 1725.12 299.594 1725.12 312.488C1725.12 325.379 1723.3 335.439 1719.84 342.835C1716.45 350.154 1711.48 355.344 1704.94 358.549Z"
                          fill="#4b5563"/>
                    <path
                        d="M1804.21 385.681V239.319C1804.21 227.958 1813.42 218.75 1824.79 218.75H1916.07C1927.43 218.75 1936.64 227.952 1936.64 239.307C1936.64 250.661 1927.43 259.863 1916.07 259.863H1861.43C1857.11 259.863 1853.62 263.361 1853.62 267.676V284.042C1853.62 288.357 1857.11 291.854 1861.43 291.854H1909.56C1920.93 291.854 1930.14 301.1 1930.14 312.456C1930.14 323.811 1920.93 333.057 1909.56 333.057H1861.43C1857.11 333.057 1853.62 336.554 1853.62 340.869V357.324C1853.62 361.639 1857.11 365.137 1861.43 365.137H1916.07C1927.43 365.137 1936.64 374.339 1936.64 385.693C1936.64 397.048 1927.43 406.25 1916.07 406.25H1824.79C1813.42 406.25 1804.21 397.042 1804.21 385.681Z"
                        fill="#4b5563"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M2875.5 68.75H125.5C94.434 68.75 69.25 93.934 69.25 125V875C69.25 906.066 94.434 931.25 125.5 931.25H2875.5C2906.57 931.25 2931.75 906.066 2931.75 875V125C2931.75 93.934 2906.57 68.75 2875.5 68.75ZM125.5 0C56.4644 0 0.5 55.9644 0.5 125V875C0.5 944.036 56.4645 1000 125.5 1000H2875.5C2944.54 1000 3000.5 944.036 3000.5 875V125C3000.5 55.9644 2944.54 0 2875.5 0H125.5Z"
                          fill="#4b5563"/>
                    <path
                        d="M2476.06 804.813C2465.52 804.813 2456.24 797.866 2453.25 787.745L2390.79 575.7C2386.3 560.452 2397.71 545.166 2413.59 545.166H2441.34C2452.44 545.166 2462.06 552.852 2464.52 563.686L2489 671.402C2491.07 680.495 2503.93 680.723 2506.32 671.71L2535.15 562.866C2537.91 552.431 2547.34 545.166 2558.13 545.166H2583.3C2594.1 545.166 2603.55 552.459 2606.29 562.921L2634.56 670.66C2636.92 679.661 2649.74 679.489 2651.86 670.428L2676.87 563.54C2679.38 552.777 2688.97 545.166 2700.01 545.166H2727.88C2743.76 545.166 2755.17 560.452 2750.68 575.7L2688.22 787.745C2685.24 797.866 2675.95 804.813 2665.41 804.813H2633.29C2622.9 804.813 2613.71 798.05 2610.6 788.117L2578.52 685.423C2575.9 677.026 2564.01 677.092 2561.48 685.518L2530.74 787.864C2527.72 797.925 2518.47 804.813 2507.98 804.813H2476.06Z"
                        fill="#4b5563"/>
                    <path
                        d="M1742.44 804.813H1666.63C1653.54 804.813 1642.92 794.157 1642.92 781.012V568.967C1642.92 555.822 1653.54 545.166 1666.63 545.166H1741.43C1768.03 545.166 1791.02 550.364 1810.38 560.76C1829.83 571.072 1844.82 585.947 1855.34 605.387C1865.95 624.742 1871.25 647.943 1871.25 674.989C1871.25 702.036 1865.99 725.279 1855.47 744.719C1844.94 764.074 1830.04 778.95 1810.76 789.346C1791.48 799.657 1768.71 804.813 1742.44 804.813ZM1713.14 721.171C1713.14 734.316 1723.75 744.972 1736.85 744.972H1739.91C1752.71 744.972 1763.61 742.902 1772.62 738.76C1781.71 734.619 1788.62 727.477 1793.33 717.334C1798.13 707.192 1800.53 693.077 1800.53 674.989C1800.53 656.902 1798.09 642.787 1793.21 632.645C1788.41 622.502 1781.34 615.36 1771.99 611.219C1762.73 607.077 1751.36 605.007 1737.89 605.007H1736.85C1723.75 605.007 1713.14 615.663 1713.14 628.808V721.171Z"
                        fill="#4b5563"/>
                    <path
                        d="M1460.86 804.813C1447.74 804.813 1437.1 794.157 1437.1 781.012V568.967C1437.1 555.822 1447.74 545.166 1460.86 545.166H1483.7C1496.83 545.166 1507.46 555.822 1507.46 568.967V724.214C1507.46 737.359 1518.1 748.015 1531.22 748.015H1588.49C1601.61 748.015 1612.25 758.671 1612.25 771.816V781.012C1612.25 794.157 1601.61 804.813 1588.49 804.813H1460.86Z"
                        fill="#4b5563"/>
                    <path
                        d="M1204.45 601.964C1191.32 601.964 1180.68 591.308 1180.68 578.163V568.967C1180.68 555.822 1191.32 545.166 1204.45 545.166H1382.34C1395.47 545.166 1406.12 555.822 1406.12 568.967V578.163C1406.12 591.308 1395.47 601.964 1382.34 601.964H1342.96C1334.75 601.964 1328.1 608.624 1328.1 616.839V781.012C1328.1 794.157 1317.46 804.813 1304.32 804.813H1282.47C1269.34 804.813 1258.69 794.157 1258.69 781.012V616.839C1258.69 608.624 1252.04 601.964 1243.83 601.964H1204.45Z"
                        fill="#4b5563"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M2223.05 787.891C2220.03 797.938 2210.78 804.813 2200.31 804.813H2174.88C2158.69 804.813 2147.24 788.951 2152.31 773.552L2222.19 561.507C2225.4 551.754 2234.5 545.166 2244.75 545.166H2306.59C2316.84 545.166 2325.94 551.754 2329.15 561.507L2399.02 773.552C2404.1 788.951 2392.65 804.813 2376.46 804.813H2351.03C2340.55 804.813 2331.31 797.938 2328.29 787.891L2321.59 765.691C2319.7 759.411 2313.93 755.115 2307.38 755.115H2243.96C2237.41 755.115 2231.64 759.411 2229.74 765.691L2223.05 787.891ZM2299.18 690.946L2285.05 642.51C2282.59 634.059 2270.69 633.908 2268.01 642.293L2252.55 690.729C2250.71 696.488 2255 702.374 2261.03 702.374H2290.63C2296.57 702.374 2300.85 696.659 2299.18 690.946Z"
                          fill="#4b5563"/>
                    <path
                        d="M1939.6 804.813C1926.47 804.813 1915.83 794.157 1915.83 781.012V568.967C1915.83 555.822 1926.47 545.166 1939.6 545.166H2027.73C2046.97 545.166 2063.81 548.674 2078.24 555.689C2092.67 562.704 2103.89 572.804 2111.91 585.989C2119.93 599.175 2123.94 614.98 2123.94 633.405C2123.94 652 2119.8 667.678 2111.53 680.441C2103.89 692.354 2093.35 701.542 2079.9 708.005C2062.92 716.164 2043.9 719.109 2025.2 719.109H1982.13C1905.57 719.109 1986.21 583.269 1986.21 634.403V642.399C1986.21 654.516 1996.02 664.34 2008.12 664.34C2016.24 664.34 2024.42 663.995 2032.16 661.297C2038.07 659.184 2042.59 655.846 2045.71 651.282C2048.91 646.717 2050.52 640.759 2050.52 633.405C2050.52 625.968 2048.91 619.924 2045.71 615.276C2042.59 610.543 2038.07 607.077 2032.16 604.88C2025.11 602.114 2017.49 601.457 2009.98 601.457C1996.85 601.457 1986.21 612.113 1986.21 625.258V672.968C1986.21 684.793 1997.35 688.971 2006.12 693.72C2018.43 700.391 2013.7 719.109 1999.7 719.109C1992.25 719.109 1986.21 725.157 1986.21 732.617V781.012C1986.21 794.157 1975.58 804.813 1962.45 804.813H1939.6ZM2074.49 698.055L2079.9 708.005L2113.41 769.627C2122.03 785.487 2110.57 804.813 2092.54 804.813H2070.27C2061.53 804.813 2053.5 800.015 2049.35 792.317L2014.3 727.277C2011.59 722.245 2006.34 719.109 2000.64 719.109C1958.4 719.109 2041.26 636.955 2074.49 698.055Z"
                        fill="#4b5563"/>
                    <path d="M931.652 0H1000.4V1000H931.652V0Z" fill="#4b5563"/>
                </svg>
            </div>

            <canvas id={`the-canvas`}/>
        </div>
    )
});

